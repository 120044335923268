var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.groupButtons
        ? [
            _vm.$slots.default && _vm.$slots.default.length > 0
              ? _c(
                  "div",
                  { staticClass: "toolbar-row-wrap pt-2 mt-0" },
                  [_vm._t("default")],
                  2
                )
              : _vm._e(),
          ]
        : [
            _vm.$slots.default && _vm.$slots.default.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "toolbar-row-nowrap fixed-btn-group pt-2 mt-0",
                  },
                  [
                    _c(
                      "b-button-group",
                      { staticClass: "mt-2 fixed-btn-group right" },
                      [_vm._t("default")],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _vm.$slots.default && Object.keys(_vm.$slots).length > 0
        ? _c("hr", { staticClass: "w-100 mb-4" })
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "margin-responsive", class: _vm.contentClasses },
        [
          _c("b-col", { attrs: { md: "6", sm: "12 pb-2" } }, [
            _c("div", { staticClass: "super-title" }, [
              _vm._v("\n        " + _vm._s(_vm.superTitle) + "\n      "),
            ]),
            _c(
              "h2",
              { attrs: { "data-cy": "title" } },
              [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
                _vm._t("title-icon"),
              ],
              2
            ),
          ]),
          _c(
            "b-col",
            { attrs: { md: "6", sm: "12" } },
            [
              _vm.showSearch
                ? _c("search", {
                    attrs: {
                      "show-search-input": _vm.showSearchInput,
                      datasource: _vm.datasource,
                      fields: _vm.fields,
                      "filter-options": _vm.filterOptions,
                      mq: _vm.$mq,
                    },
                    on: { changed: _vm.searchChanged },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }